import React from 'react';
import './Team.css'

// import img1 from '../Images/slide-1.jpg' 
// import img2 from '../Images/slide-2.jpg'  
// import img3 from '../Images/slide-3.jpg' 
// import img9 from '../Images/slide-4.jpg' 
import img1 from '../Images/Team/Mteam1.jpg' 
import img2 from '../Images/Team/Mteam2.jpg' 
import img3 from '../Images/Team/Mteam3.jpg' 
import img4 from '../Images/Team/Mteam4.jpg' 
import Listing from '../../componentsii/Listing/Listing';
import SlideCarousel from '../SlideCarousel/SlideCarousel';
// import SlideCarousel from '../SlideCarouselSec/SlideCarousel';

function Team() { 


   const cardData = [ 
      { 
         ImgUrl: img1,
         Name: "Gabriel Mwanza", 
         Title: "Lead Photographer",
         Price: 300,
         location : "Chilanga Post Office, Chilanga, Zambia",
         ID  : 1

   
      },
      { 
         ImgUrl: img2,
         Name: "Gabriel Siachigata", 
         Title: "Light Assnt.",
         Price: 250,
         location : "Findeco House, Lusaka, Zambia",
         ID  : 2
   
      }, 
       { 
         ImgUrl: img3,
         Name: "Brighton Chabi", 
         Title: "Videographer",
         Price: 300,
         location : "Down town mall, Lusaka, Zambia",
         ID  : 3
   
      }, 
       
       { 
         ImgUrl: img4,
         Name: "Joel Mwanza", 
         Title: "Assnt. Photographer",
         Price: 300,
         location : "Down town mall, Lusaka, Zambia",
         ID  : 3
   
      }, 
       
   
   
     ]




  return  <div className="MeetUs"> 
       

              <div className="TContainer">  

                    <div className="Headingz"><h3> MEET US</h3> </div>
                    <div  className="subHeadingz"><h1> MOSH MEDIA TEAM</h1> </div>

                 </div> 
                 
                 {/* <div className='listingTeam'>
                     <Listing/>
                 </div> */}

                 <div className='slideTeam'> 
                 
                     <SlideCarousel
                           CarouselData={cardData}
                           Heading={"Skin Care"}
                     />

                 </div>

   
    
           {/* <div className="Cardsss">
           
            {  
            cardData.map((item,index)=>{ 
                return( 

                    <div className="CardHolderSec" key={index}> 
                    
                                    <div className="ImageHolder">
                                    <img src={item.ImgUrl}/> 
                                         <div className="overlays"> 

                                        
                                                <div className="ImageTextz"> 
                                                
                                                      <i className="fab fa-facebook"></i>
                                                      <i className="fab fa-twitter"></i>
                                                      <i className="far fa-envelope"></i>
                                                      <i className="fab fa-whatsapp"></i>
                                                      <i className="fab fa-linkedin"></i>
                                                      

                                                </div>  
                                           
                                    
                                    </div>
                                    </div>

                                    <div className="ImageText"> 
                                     
                                     <h3>{item.Name} </h3> 
                                     <p> {item.Title}</p>

                                    </div>  

                                  
                    
                    
                    </div>

          
                )

            }) 
         }
           
            </div> */}
        
   

     
     
     
    
            
             

  </div>;
}

export default Team;


    