import React from 'react'
import './AboutUs.css'
import NavBar2 from '../../Components/NavBar2/HeadingOne' 
import About from '../../Components/About/About' 
import Footer from '../../Components/Foooter/Footer' 
import ScrollToTop from '../../Components/Scrolltop/Scrolltop'
import Operation from '../../Components/Operation/Operation'

function AboutUs() {
  return (
    <> 
        <ScrollToTop/>
        <NavBar2/>
        <About/> 
        <Operation/>
        <Footer/>
    
    </>
   
  )
}

export default AboutUs