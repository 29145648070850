import React from 'react';
import './Footer.css'
function Footer() {
  return <div className='Copyright'>

          <p>Gabriel Mwanza @ 2024</p>
  </div>;
}

export default Footer;
