




import { Link } from "react-router-dom";
import "./ProductWording.css"
import img1 from "../../Components/Images/Latest/Latest_2.jpg"



function ProductWording() {

        // const BookLink ="https://m.me/Nerdarr"
        const BookLink ="https://booking.setmore.com/scheduleappointment/91a2a4b5-90b5-4f0c-8ae0-b7df8d8381d2"
    
    
  return (

        <div className="ProductWordingContainer">   


            <div className="ProductWordingCover">  

                  
                    <div className="wordPart"> 

                            <h1>Mosh Media</h1>
                            <p>Step into the captivating world of Mosh Media, We are because we believe photography should be a passionate journey, not a pricey expedition. We're dedicated to capturing your moments with flair and finesse.</p>
                            <button><Link className='text-link' to={BookLink}><p><b>Book Appointment</b></p></Link></button>
                            
                            {/* <button>Book Appointment</button> */}
                    </div>
                      <div className="imagePart"> 

                            <img src={img1} />

                    </div>
            
             </div>


            
        </div>
  );
}

export default ProductWording;
