import React, { useEffect } from 'react'  
import {Routes,Route,Navigate,Link} from 'react-router-dom'
import './Heading.css'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCoffee,faBars } from '@fortawesome/free-solid-svg-icons'
import NavBarSec from '../NavBarSec/NavBarSec'


function Heading() {

  const [scrollState , setScrollState] = useState(false); 
  let listener = null; 

//changes NavBar after some scroll height
  useEffect(()=>{
     
        listener = document.addEventListener('scroll',e =>{
          var scrolled = document.scrollingElement.scrollTop; 
          if(scrolled >=120){
            if(scrollState !== true) setScrollState(true)
          }else{
            if(scrollState !== false) setScrollState(false)
          }

        }) 

        return () =>{
          document.removeEventListener('scroll',listener);
        }

  },[scrollState])


    return ( <>
         <NavBarSec/>
   <div  className="Heading">   


              



         
               <div className='Overhead'>  
                  
                          
                          <div className='firstOver'>
                              <h1> MOSH</h1>
                          </div>
                          <div className='SecondOver'>
                            <h1> PHOTOGRAPHY</h1>
                          </div>
                        
                      

               </div>

                     


               



                </div>   


      
      </>   

    )
}

export default Heading
