import React from 'react'
import './Work.css'

// import img1 from '../../Components/Images/slide-1.jpg' 
// import img2 from '../../Components/Images/slide-2.jpg'  
// import img3 from '../../Components/Images/slide-3.jpg' 
import img1 from '../../Components/Images/Portfolio/Wedding/Wed1.jpg' 
import img2 from '../../Components/Images/Portfolio/Wedding/Wed2.jpg' 
import img3 from '../../Components/Images/Portfolio/Wedding/Wed3.jpg' 
import img4 from '../../Components/Images/Portfolio/Wedding/Wed4.jpg' 
import img5 from '../../Components/Images/Portfolio/Wedding/Wed5.jpg' 
import img6 from '../../Components/Images/Portfolio/Wedding/Wed6.jpg' 
import img7 from '../../Components/Images/Portfolio/Wedding/Wed7.jpg' 
import img8 from '../../Components/Images/Portfolio/Wedding/Wed8.jpg' 
import img9 from '../../Components/Images/Portfolio/Wedding/Wed9.jpg' 
import img10 from '../../Components/Images/Portfolio/Wedding/Wed10.jpg' 
import SlideCarouselSec from '../../Components/SlideCarouselSec/SlideCarouselSec'


import Cop1 from "../../Components/Images/Corporate/Cop1.jpg"
import Cop2 from "../../Components/Images/Corporate/Cop2.jpg"
import Cop3 from "../../Components/Images/Corporate/Cop3.jpg"
import Cop4 from "../../Components/Images/Corporate/Cop4.jpg"
import Cop5 from "../../Components/Images/Corporate/Cop5.jpg"
import Cop6 from "../../Components/Images/Corporate/Cop6.jpg"
import Cop7 from "../../Components/Images/Corporate/Cop7.jpg"
import Cop8 from "../../Components/Images/Corporate/Cop8.jpg"
import Cop9 from "../../Components/Images/Corporate/Cop9.jpg"


import Other1 from "../../Components/Images/Portfolio/Other/Other1.jpg"
import Other2 from "../../Components/Images/Portfolio/Other/Other2.jpg"
import Other3 from "../../Components/Images/Portfolio/Other/Other3.jpg"
import Other4 from "../../Components/Images/Portfolio/Other/Other4.jpg"
import Other5 from "../../Components/Images/Portfolio/Other/Other5.jpg"
import Other6 from "../../Components/Images/Portfolio/Other/Other6.jpg"
import Other7 from "../../Components/Images/Portfolio/Other/Other7.jpg"
import Other8 from "../../Components/Images/Portfolio/Other/Other8.jpg"
import Other9 from "../../Components/Images/Portfolio/Other/Other9.jpg"
import Other10 from "../../Components/Images/Portfolio/Other/Other10.jpg"
import Other11 from "../../Components/Images/Portfolio/Other/Other11.jpg"


const Corporate = [ 
   
    { 
        ImgUrl : Cop1, 
        Names : "SOFT & BAE"
    } ,
    { 
       ImgUrl : Cop2, 
       Names : "SOFT & BAE1"
   } ,
   { 
       ImgUrl : Cop3, 
       Names : "SOFT & BAE2"
   } ,
   { 
       ImgUrl : Cop4, 
       Names : "SOFT & BAE3"
   } ,
   { 
       ImgUrl : Cop5, 
       Names : "SOFT & BAE4"
   } ,
   { 
       ImgUrl : Cop6, 
       Names : "SOFT & BAE5"
   } ,
   { 
       ImgUrl : Cop7, 
       Names : "SOFT & BAE5"
   } ,
   { 
       ImgUrl : Cop8, 
       Names : "SOFT & BAE5"
   } ,
   { 
       ImgUrl : Cop9, 
       Names : "SOFT & BAE5"
   } ,
    


]
const Other = [ 
   
    { 
        ImgUrl : Other1, 
        Names : "SOFT & BAE"
    } ,
    { 
       ImgUrl : Other2, 
       Names : "SOFT & BAE1"
   } ,
   { 
       ImgUrl : Other3, 
       Names : "SOFT & BAE2"
   } ,
   { 
       ImgUrl : Other4, 
       Names : "SOFT & BAE3"
   } ,
   { 
       ImgUrl : Other5, 
       Names : "SOFT & BAE4"
   } ,
   { 
       ImgUrl : Other6, 
       Names : "SOFT & BAE5"
   } ,
   { 
       ImgUrl : Other7, 
       Names : "SOFT & BAE5"
   } ,
   { 
       ImgUrl : Other8, 
       Names : "SOFT & BAE5"
   } ,
   { 
       ImgUrl : Other9, 
       Names : "SOFT & BAE5"
   } ,
    


]

const shootss = [ 
   
    { 
        ImgUrl : img1, 
        Names : "SOFT & BAE"
    } ,
    { 
       ImgUrl : img2, 
       Names : "SOFT & BAE1"
   } ,
   { 
       ImgUrl : img3, 
       Names : "SOFT & BAE2"
   } ,
   { 
       ImgUrl : img4, 
       Names : "SOFT & BAE3"
   } ,
   { 
       ImgUrl : img5, 
       Names : "SOFT & BAE4"
   } ,
   { 
       ImgUrl : img6, 
       Names : "SOFT & BAE5"
   } ,
    


]



 

const Images = [ 

        //     {   "Bride":"TEMA",
        //         "Category": "Bridal Showers",
        //         "imgUrl": img1
        //     }, 
        //     {
        //         "Bride":"CHALWE",
        //         "Category": "Bridal Showers",
        //         "imgUrl": img2
        //     },
        //     {
        //         "Bride":"DOREEN",
        //         "Category": "Bridal Showers",
        //         "imgUrl": img3
        //     },
        //     {   "Bride":"MABOSHE",
        //     "Category": "Bridal Showers",
        //     "imgUrl": img1
        // }, 
        // {
        //     "Bride":"SARAH",
        //     "Category": "Bridal Showers",
        //     "imgUrl": img2
        // },
        // {
        //     "Bride":"MBOLOLWA",
        //     "Category": "Bridal Showers",
        //     "imgUrl": img3
        // }, 
        // {
        //     "Bride":"MBOLOLWA",
        //     "Category": "Bridal Showers",
        //     "imgUrl": img3
        // }, 
        {
            "imgUrl": img1
        }, 
        {
            "imgUrl": img2
        }, 
        {
            "imgUrl": img3
        }, 
        {
            "imgUrl": img4
        }, 
        {
            "imgUrl": img5
        }, 
        {
            "imgUrl": img6
        }, 
        {
            "imgUrl": img7
        }, 
        {
            "imgUrl": img8
        }, 
        {
            "imgUrl": img9
        }, 
        {
            "imgUrl": img10
        }, 

]

function Work() {
  return ( 
      <>    

      <div className='SubHeadingWork'> 
             <h1>Gallery</h1>
         </div> 

    {/* <div className='Work'>   
     
     <div className='SubHeading'> 
             <h1>Weddings</h1>
         </div> 

    <div className='WGallery'>   

        { Images.map(
            (item,index) =>{ 
            
                return( 
                    
                    <div className='Cover'>  
                            <div className='coupleWrap'> 
                                                
                                            
                            
                                                <div className='WImages'>  
                            
                                                        <img src={item.imgUrl} />
                                                    
                                                
                                                </div>      
                            
                                    </div>  
                            
                                    
                                   

                </div>  
                )

            }       

        )}




    
      
    </div> 
        
  </div>    */}


  {/* <div className='Work'>   
     
     <div className='SubHeading'> 
             <h1>Other Sessions</h1>
         </div> 

    <div className='WGallery'>   

        { Images.map(
            (item,index) =>{ 
            
                return( 
                    
                    <div className='Cover'>  
                            <div className='coupleWrap'> 
                                                
                                         
                                                <div className='WImages'>  
                            
                                                        <img src={item.imgUrl} />
                                                    
                                                
                                                </div>      
                            
                                    </div>  
                            
                               

                </div>  
                )

            }       

        )}




    
      
    </div> 
        
  </div>   */}


  <div>
     <SlideCarouselSec  

             CarouselData={shootss}
                    Heading={"Weddings"}
     
     />
  </div>
  <div>
     <SlideCarouselSec  

             CarouselData={shootss}
                    Heading={"Chilanga Mulilos"}
     
     />
  </div>
  <div>
     <SlideCarouselSec  

             CarouselData={Corporate}
                    Heading={"Corporate"}
     
     />
  </div>
  <div>
     <SlideCarouselSec  

             CarouselData={Other}
                    Heading={"Just! ;)"}
     
     />
  </div>
  <br/>
    




</>

  )
}

export default Work 



    