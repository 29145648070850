import React, { useState } from 'react'
import './Blog.css' 
import NavBar2 from '../../Components/NavBar2/HeadingOne' 
import Column from '../../blogComponents/Columns/Column'
import Footer from '../../Components/Foooter/Footer' 
// import  { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import ScrollToTop from '../../Components/Scrolltop/Scrolltop'

function Blog() { 

  let {BID} = useParams();

  const [bid, setBid] = useState(BID !=undefined?BID:1);

 
  return (
    <div> 
        <ScrollToTop/>
        <NavBar2/>
        <Column bIDProp={bid}/>
        <Footer/>

    </div>
  )
}

export default Blog