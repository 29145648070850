import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    space: "1cvwb1pgfnhx",
    accessToken: "yLI66C33RqXTMrxoU5AAb9BCI_TjGfKd-mPS70mYupk",
    host: "cdn.contentful.com"
  });
//   const client = createClient({
//     space: "kp7895evg2nd",
//     accessToken: "eieMViKbcQqp9ejVR437kMmqZ7SagfqOOTKWZGfESRs",
//     host: "cdn.contentful.com"
//   });

  const getAuthors = async () => {
    try {
      const entries = await client.getEntries({
        // content_type: "Product",
        // select: "fields"
      });

      console.log("entries",entries)
      const sanitizedEntries = entries.items.map((item) => {
        const avatar = item.fields.productName;
        return {
          ...item.fields,
          avatar
        };
      });

      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching authors ${error}`);
    }
  };

  return { getAuthors };
};

export default useContentful;

// import { createClient } from "contentful";

// const useContentful = () => {
//   const client = createClient({
//     space: "f980zcpmf0m0",
//     accessToken: "5l6J_HtfcFdShJuUXrmKwDBR0rejdhvgRgEHqv2zb6U",
//     host: "preview.contentful.com"
//   });

//   const getAuthors = async () => {
//     try {
//       const entries = await client.getEntries({
//         content_type: "author",
//         select: "fields"
//       });

//       const sanitizedEntries = entries.items.map((item) => {
//         const avatar = item.fields.avatar.fields;
//         return {
//           ...item.fields,
//           avatar
//         };
//       });

//       return sanitizedEntries;
//     } catch (error) {
//       console.log(`Error fetching authors ${error}`);
//     }
//   };

//   return { getAuthors };
// };

// export default useContentful;