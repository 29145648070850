import React from 'react';

import NavBar2 from "../../Components/NavBar2/HeadingOne"
import Consideration from '../../componentsii/Consideration/Consideration';
import Section from '../../componentsii/Section/Section';
import Footer from '../../Components/Foooter/Footer'
import Operation from '../../Components/Operation/Operation';

function Pricing() {
  return <div> 
   
     <NavBar2/> 
     <Section/>
     <Consideration/>
     <Operation/>
     <Footer/>


  </div>;
}

export default Pricing;
